import Common from 'GroomyRoot/assets/js/common.js'
import Constants from 'Constants'

var CroissanceMixin = {
	methods: {
		addCroissance: async function(horse_id, params) {
            const url = this.constructRoute(Constants.HORSE_CROISSANCE, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("CroissanceMixin::saveCroissance", url, params, false)
        },

        getCroissanceByHorse: async function(horse_id) {
            const url = this.constructRoute(Constants.HORSE_CROISSANCE, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CroissanceMixin::getCroissanceByHorse", url)
            .catch(error => {
                console.error("CroissanceMixin::getCroissanceByHorse => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        getCroissanceById: async function(horse_id, croissance_id) {
            const url = this.constructRoute(Constants.HORSE_CROISSANCE_BY_ID, { horse_id, croissance_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("CroissanceMixin::getCroissanceById", url)
            .catch(error => {
                console.error("ContractMixin::getCroissanceById => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        updateCroissance: async function(horse_id, croissance_id, params) {
            const url = this.constructRoute(Constants.HORSE_CROISSANCE_BY_ID, { horse_id, croissance_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("CroissanceMixin::saveCroissance", url, params, false)
        },


        deleteCroissance: async function(horse_id, croissance_id) {
            const url = this.constructRoute(Constants.HORSE_CROISSANCE_BY_ID, { horse_id, croissance_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_delete_api("CroissanceMixin::deleteCroissance", url)
        },

        getCroissanceComparaison: async function(horse_id, weeks) {
            const url = this.constructRoute(Constants.HORSE_CROISSANCE_COMPARE, { horse_id, weeks }) +'?licence_key='+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("CroissanceMixin::getCroissanceComparaison", url)
            .catch(error => {
                console.error("ContractMixin::getCroissanceComparaison => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        }
	}
}

export default CroissanceMixin