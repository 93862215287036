<template>
	<div class="box">
		<div class="text-center">
			<b-button-group>
				<b-button variant="outline-secondary" @click="setDuration(26)">{{ $t('croissance.six_mois') }}</b-button>
				<b-button variant="outline-secondary" @click="setDuration(52)">{{ $t('croissance.un_an') }}</b-button>
				<b-button variant="outline-secondary" @click="setDuration(104)">{{ $t('croissance.deux_ans') }}</b-button>
			</b-button-group>
		</div>
		<template v-if="ready">
			<h4>{{ $t('croissance.courbe_taille') }}</h4>
			<div class="box box-white">
				<apexchart type="area" height="350" :options="chartOptions" :series="series_garrot"></apexchart>
			</div>
			<h4>{{ $t('croissance.courbe_poids') }}</h4>
			<div class="box box-white">
				<apexchart type="area" height="350" :options="chartOptions" :series="series_poids"></apexchart>
			</div>
		</template>
		<LoadingSpinner v-else />
	</div>
</template>

<script type="text/javascript">
	import VueApexCharts from 'vue-apexcharts'
	import Croissance from "@/mixins/Croissance.js"


	export default {
		name: 'HorseCroissanceGraph',
		mixins: [Croissance],
		props: ['horse_id'],
		data () {
			return {
				series_garrot: [],
				series_poids: [],
				chartOptions: {
					chart: {
						height: 350,
						type: 'line',
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						show: true,
						curve: 'straight'
					},
					xaxis: {
						type: 'string',
						categories: [],
						convertedCatToNumeric: false,
						title: {
							text: this.getTrad('croissance.age_poulain')
						},
						labels: {
							rotate: 0,
							hideOverlappingLabels: true,
							style: {
								cssClass: "label-chart"
							}
						}
					},
					yaxis: {
						labels: {
							style: {
								cssClass: "label-chart"
							}
						}
					},
					colors: ['#C92B3C', '#2AA31C', '#818181', '#1B1B1B', '#FFDB58'],
					legend: {
						markers: {
							width: 24,
							height: 8,
							radius: 0,
						},
						itemMargin: {
							vertical: 25
						}
					},
					annotations: {
						xaxis: [
							{
								x: ' S25 ',
								x2: ' S30 ',
								fillColor: '#B3F7CA',
								label: {
									text: 'Sevrage'
								}
							},
							{
								x: ' S70 ',
								x2: ' S72 ',
								fillColor: '#B3F7CA',
								label: {
									text: '500 jours'
								}
							}
						]
					}
				},
				duration: 26,
				ready: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.ready = false
				this.series_garrot = []
				this.series_poids = []

				const data = await this.getCroissanceComparaison(this.horse_id, this.duration)
				
				let horse_garrot = [], horse_poids = [], weeks = []
				let lhg = null, phg = null

				if(!data.avg_race && !data.horse_avg && !data.avg_pere && !data.avg_mere && !data.avg_own){

					let max_horse = data.horse ? Math.max(...data.horse.map(d => d.croissance_week)) : 0

					for(let i = 1; i <= this.duration; i++) {
						weeks.push('S'+i)

						const horse_week = data.horse.find(cr => cr.croissance_week == i)
						if(horse_week) {
							if(horse_week.croissance_garrot) {
								lhg = horse_week.croissance_garrot
							}
							if(horse_week.croissance_poids) {
								phg = horse_week.croissance_poids
							}
						}
						if(i > max_horse) {
							lhg = null
							phg = null
						}
						horse_garrot.push({x: "S"+i, y: lhg})
						horse_poids.push({x: "S"+i, y: phg})
					}

					this.series_garrot = [{
						type: 'line',
						name: 'Cheval',
						data: horse_garrot
					}]
					this.series_poids = [{
						type: 'line',
						name: 'Cheval',
						data: horse_poids
					}]

					this.chartOptions.colors = ['#C92B3C']

				}else{
					let race_garrot = [], pere_garrot = [], mere_garrot = [], own_garrot = [], race_poids = [], pere_poids = [], mere_poids = [], own_poids = []

					let lrg = null, lpg = null, lmg= null, log = null, prg = null, ppg = null, pmg= null, pog = null;

					let max_horse = data.horse ? Math.max(...data.horse.map(d => d.croissance_week)) : 0
					let max_race = data.avg_race ? Math.max(...data.avg_race.map(d => d.croissance_week)) : 0
					let max_pere = data.avg_pere ? Math.max(...data.avg_pere.map(d => d.croissance_week)) : 0
					let max_mere = data.avg_mere ? Math.max(...data.avg_mere.map(d => d.croissance_week)) : 0
					let max_own = data.avg_own ? Math.max(...data.avg_own.map(d => d.croissance_week)) : 0

					for(let i = 1; i <= this.duration; i++) {
						weeks.push('S'+i)

						const horse_week = data.horse.find(cr => cr.croissance_week == i)
						if(horse_week) {
							if(horse_week.croissance_garrot) {
								lhg = horse_week.croissance_garrot
							}
							if(horse_week.croissance_poids) {
								phg = horse_week.croissance_poids
							}
						}
						if(i > max_horse) {
							lhg = null
							phg = null
						}
						horse_garrot.push({x: "S"+i, y: lhg})
						horse_poids.push({x: "S"+i, y: phg})

						const race_week = data.avg_race.find(cr => cr.croissance_week == i) 
						if(race_week) {
							if(race_week.garrot) {
								lrg = race_week.garrot
							}
							if(race_week.poids) {
								prg = race_week.poids
							}
						}
						if(i > max_race) {
							lrg = null
							prg = null
						}
						race_garrot.push({x: "S"+i, y: lrg})
						race_poids.push({x: "S"+i, y: prg})

						const pere_week = data.avg_pere.find(cr => cr.croissance_week == i)
						if(pere_week) {
							if(pere_week.garrot) {
								lpg = pere_week.garrot
							}
							if(pere_week.poids) {
								ppg = pere_week.poids
							}
						}
						if(i > max_pere) {
							lpg = null
							ppg = null
						}
						pere_garrot.push({x: "S"+i, y: lpg})
						pere_poids.push({x: "S"+i, y: ppg})

						const mere_week = data.avg_mere.find(cr => cr.croissance_week == i)
						if(mere_week) {
							if(mere_week.garrot) {
								lmg = mere_week.garrot
							}
							if(mere_week.poids) {
								pmg = mere_week.poids
							}
						}
						if(i > max_mere) {
							lmg = null
							pmg = null
						}
						mere_garrot.push({x: "S"+i, y: lmg})
						mere_poids.push({x: "S"+i, y: pmg})					

						const own_week = data.avg_own.find(cr => cr.croissance_week == i)
						if(own_week) {
							if(own_week.garrot) {
								log = own_week.garrot
							}
							if(own_week.poids) {
								pog = own_week.poids
							}
						}
						if(i > max_own) {
							log = null
							pog = null
						}
						own_garrot.push({x: "S"+i, y: log})
						own_poids.push({x: "S"+i, y: pog})
					}

					let nb_race = data.avg_race ? [...new Set(data.avg_race.map(d => d.croissance_horse))].length : 0
					let nb_pere = data.avg_pere ? [...new Set(data.avg_pere.map(d => d.croissance_horse))].length : 0
					let nb_mere = data.avg_mere ? [...new Set(data.avg_mere.map(d => d.croissance_horse))].length : 0
					let nb_own = data.avg_own ? [...new Set(data.avg_own.map(d => d.croissance_horse))].length : 0

					let label_race = nb_race > 0 ? ' ('+nb_race+')' : ''
					let label_pere = nb_pere > 0 ? ' ('+nb_pere+')' : ''
					let label_mere = nb_mere > 0 ? ' ('+nb_mere+')' : ''
					let label_own = nb_own > 0 ? ' ('+nb_own+')' : ''

					this.series_garrot = [
						{
							type: 'line',
							name: this.getTrad('croissance.poulain'),
							data: horse_garrot
						},
						{
							type: 'line',
							name: this.getTrad('croissance.meme_race') + label_race,
							data: race_garrot
						},
						{
							type: 'line',
							name: this.getTrad('croissance.meme_pere') + label_pere,
							data: pere_garrot
						},
						{
							type: 'line',
							name: this.getTrad('croissance.meme_mere') + label_mere,
							data: mere_garrot
						},
						{
							type: 'line',
							name: this.getTrad('croissance.mes_poulains') + label_own,
							data: own_garrot
						}
					]

					this.series_poids = [
						{
							type: 'line',
							name: this.getTrad('croissance.poulain'),
							data: horse_poids
						},
						{
							type: 'line',
							name: this.getTrad('croissance.meme_race') + label_race,
							data: race_poids
						},
						{
							type: 'line',
							name: this.getTrad('croissance.meme_pere') + label_pere,
							data: pere_poids
						},
						{
							type: 'line',
							name: this.getTrad('croissance.meme_mere') + label_mere,
							data: mere_poids
						},
						{
							type: 'line',
							name: this.getTrad('croissance.mes_poulains') + label_own,
							data: own_poids
						}
				]

					this.chartOptions.colors = ['#C92B3C', '#2AA31C', '#818181', '#1B1B1B', '#FFDB58']
				}
				this.chartOptions.xaxis.categories = weeks
				this.ready = true
			},

			setDuration(mounth) {
				this.duration = mounth
				this.init_component()
			}
		},

		components: {
			apexchart: VueApexCharts,
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') 
		}
	}

</script>